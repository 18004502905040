<template>
  <div class="top-article">
    <!--  置顶模块  -->
    <div class="block" v-for="list in tops" :key="list.id" @click="viewDetail(list.id)">
      <div class="topping-title">{{ list.title }}</div>
      <div class="topp">
        <span class="iconfont log">&#xe68d;</span>
        <span>{{ list.author.nickname }}·</span>
        <span class="top-s2">{{ list.createTime | compareDate }}</span>
        <span v-if="list.comments > 0">{{ list.comments }} 评论</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['tops'],
  methods: {
    viewDetail(id) {
      this.$router.push({
        path: `note/${id}`,
      })
    },
  },
}
</script>

<style lang="less">
.top-article {
  margin: 14px 13px 0;
  border-bottom: 1px solid #f6f6f6;
  .block {
    margin-bottom: 14px;
  }
  .topping-title {
    font-size: 17px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #242831;
    line-height: 22px;
    letter-spacing: 1px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .topp {
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #afb5bf;
    margin-top: 6px;
    display: flex;
    align-items: center;
    .log {
      line-height: 16px;
      font-size: 25px;
      color: #F7321C;
      margin-right: 10px;
      // background: #ffe8e3;
    }
  }
}
</style>
